import { Component, Inject, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ServicesService } from 'src/app/core/services/services.service';

@Component({
  selector: 'app-preview-files',
  templateUrl: './preview-files.component.html',
  styleUrls: ['./preview-files.component.scss'],
})
export class PreviewFilesComponent implements OnInit, OnChanges, OnDestroy {
  source_secure: any;
  source: any;
  isPdf: boolean = true;
  action?: 'PREVIEW_IMG' | 'PREVIEW_BLOB' | 'PREVIEW_DOC' = 'PREVIEW_IMG';
  pdfBlobUrl: string | undefined;
  isLoading = false;

  constructor(
    public sanitizer: DomSanitizer,
    private services: ServicesService,
    public dialogRef: MatDialogRef<PreviewFilesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      source: string | Blob;
      billId: string;
      isImage: boolean;
      action?: 'PREVIEW_IMG' | 'PREVIEW_BLOB' | 'PREVIEW_DOC';
      is_on_the_fly?: boolean;
    }
  ) {
    this.source_secure = this.sanitizer.bypassSecurityTrustUrl(this.source)!;
    this.sanitizer.bypassSecurityTrustUrl(this.source);
  }

  ngOnInit() {
    this.handleSource();
  }

  ngOnChanges() {
    this.handleSource();
  }

  handleSource(): void {
    this.isLoading = true;
    const { source, action } = this.data;
    this.action = action;
    this.source = source;

    if (action === 'PREVIEW_BLOB' && source instanceof Blob) {
      this.isPdf = source.type === 'application/pdf';
      if (this.isPdf) {
        const url = URL.createObjectURL(source);
        this.source_secure = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    } else if (action === 'PREVIEW_IMG') {
      this.isPdf = false;
      this.source_secure = this.sanitizer.bypassSecurityTrustUrl(
        source as string
      );
    } else if (action === 'PREVIEW_DOC') {
      this.source_secure = this.sanitizer.bypassSecurityTrustResourceUrl(
        source as string
      );
    }

    if (this.data.isImage) this.isLoading = false;
  }

  close(): void {
    this.dialogRef.close(this.action);
  }

  ngOnDestroy(): void {
    if (this.pdfBlobUrl) {
      URL.revokeObjectURL(this.pdfBlobUrl); // Libérer l'URL Blob si elle est utilisée
    }

    if (this.source_secure) {
      URL.revokeObjectURL(this.source_secure); // Libérer l'URL Blob
    }
  }

  onPageRendered(): void {
    this.isLoading = false;
  }
}
