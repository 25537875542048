import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HealthProviderType } from 'src/app/core/enums/enums';
import { ImageProfil } from 'src/app/core/models/ImageProfil';

@Component({
  selector: 'app-images-viewer',
  templateUrl: './images-viewer.component.html',
  styleUrls: ['./images-viewer.component.scss'],
})
export class ImagesViewerComponent implements OnInit, OnDestroy {
  @ViewChild('owlCarousel') owlCarousel!: any;
  @Input() images!: ImageProfil[];
  @Input() currentImage!: ImageProfil;
  @Input() _caller: 'ADMIN' | 'KIMBO' = 'KIMBO';
  @Input() type!: string;
  @Input() isLoading: boolean = false;

  @Output() clickOutside: EventEmitter<any> = new EventEmitter();
  @Output() addPicture: EventEmitter<any> = new EventEmitter();
  @Output() updatePicture: EventEmitter<any> = new EventEmitter();
  @Output() updateProfil: EventEmitter<any> = new EventEmitter();

  healthProviderType = HealthProviderType;
  currentIndex: number = 0;

  customOptions!: OwlOptions;

  value?: File | null;

  slideAuto: any;

  timeoutSlide: number = 3000;

  constructor(private breakpointObserver: BreakpointObserver) {
    let countItem = 3;
    breakpointObserver.observe('(min-width: 815px)').subscribe(state => {
      if (state.matches) {
        countItem = 4;
      }
    });

    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: false,
      dots: false,
      navSpeed: 700,
      items: countItem,
      autoWidth: true,
      autoHeight: false,
      margin: 10,
      autoplay: false,
      center: false,
    };
  }

  ngOnInit(): void {
    this.initDatas();

    if (this._caller === 'KIMBO' && this.images.length > 1) {
      this.customOptions.loop = true;
      this.startAutoSlider();
    }
  }

  ngOnDestroy(): void {
    this.clearAutoSlider();
  }

  showImage(image: ImageProfil, index: number) {
    this.currentImage = image;
    this.currentIndex = index;
  }

  remove() {
    this.clickOutside.emit(this.currentImage);
    this.initDatas();
  }

  updateProfile() {
    this.updateProfil.emit(this.currentImage);
    this.initDatas();
  }

  updateImage(evt: any) {
    this.value = evt.target.files;
    if (this.value) {
      this.updatePicture.emit({
        id: this.currentImage.id,
        file: evt.target.files?.item(0) ?? null,
      });
    }
    this.initDatas();
  }

  next() {
    if (this._caller == 'ADMIN') {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    } else {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    }
    this.currentImage = this.images[this.currentIndex];

    this.owlCarousel.next();
  }

  preview() {
    if (this._caller == 'ADMIN') {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = 0;
      }
    } else {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.images.length - 1;
      }
    }
    this.currentImage = this.images[this.currentIndex];
    this.owlCarousel.prev();
  }

  setFieldValue(event: any): void {
    if (this.images!.length >= 8) {
      return;
    }
    this.addPicture.emit(event);
    this.initDatas();
  }

  initDatas() {
    let existProfilImage = false;
    this.images.forEach((image, index) => {
      if (image.type == 'image_profil') {
        this.currentImage = image;
        this.currentIndex = index;
        existProfilImage = true;
        return;
      }
    });
    if (!existProfilImage) {
      this.currentIndex = 0;
      this.currentImage = this.images[this.currentIndex];
    }
  }

  startAutoSlider() {
    if (this._caller === 'KIMBO' && this.images.length > 1) {
      this.slideAuto = setInterval(() => {
        this.next();
      }, this.timeoutSlide);
    }
  }

  clearAutoSlider() {
    if (this.slideAuto) {
      clearInterval(this.slideAuto);
    }
  }
}
