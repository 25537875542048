import { Currency } from '../models/Common';

export function showMobilePaiement(
  currency_code: string,
  country: string
): boolean {
  if (
    ((currency_code === 'XAF' || currency_code === 'CFA') &&
      (country === 'Cameroun' || country === 'Côte d’Ivoire')) ||
    (currency_code === 'KES' && country === 'Kenya')
  ) {
    return true;
  }
  return false;
}

export function getCountryByPhoneNumber(
  phoneNumber: string | undefined
): string | undefined {
  if (
    phoneNumber?.trim().startsWith('+237') ||
    phoneNumber?.trim().startsWith('00237') ||
    phoneNumber?.trim().startsWith('237')
  ) {
    return 'Cameroun';
  } else if (
    phoneNumber?.trim().startsWith('+225') ||
    phoneNumber?.trim().startsWith('00225') ||
    phoneNumber?.trim().startsWith('225')
  ) {
    return 'Côte d’Ivoire';
  } else if (
    phoneNumber?.trim().startsWith('+254') ||
    phoneNumber?.trim().startsWith('00254') ||
    phoneNumber?.trim().startsWith('254')
  ) {
    return 'Kenya';
  }
  return undefined;
}

export function makeCurrencytoShow(currency: string) {
  return currency === 'XAF' ? 'CFA' : currency;
}

/**
 * Native scrollTo with callback
 * @param offset - offset to scroll to
 * @param callback - callback function
 */
export function scrollTo(offset: number, callback: any) {
  const fixedOffset = offset.toFixed();
  const onScroll = function () {
    if (window.pageYOffset.toFixed() === fixedOffset) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll();
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
}

/**
 * capitalizeSentence() Function to Capitalize sentence in typescript
 * @params sentence
 * @returns capitalizedSentence
 */
export function capitalizeSentence(sentence: string): string {
  if (!sentence) return sentence;
  return sentence
    .split(/\s+/)
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
}
