import { Injectable, OnDestroy, OnInit, inject } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ServicesService } from './services.service';
import { LanguageService } from './language.service';
import { PermissionEnums } from '../enums/permission-enums';
import { AdminRoleType } from '../enums/enums';
import { AdminRoleService } from './admin-role.service';
// Menu
export interface Menu {
  headTitle?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeValue?: string;
  badgeClass?: string;
  active?: boolean;
  selected?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  children2?: Menu[];
  Menusub?: boolean;
  target?: boolean;
  rights?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  // Language
  public language = false;

  // Mega Menu
  public megaMenu = false;
  public levelMenu = false;

  // Full screen
  public fullScreen = false;
  active: any;

  translations: any = {
    navAdminHub: '',
    navOperationsHub: '',
    navFinanceHub: '',
    navSupportHub: '',
    navMarketingHub: '',
    navDashboard: '',
    navUsersTeamsRoles: '',
    navGeneralSettings: '',
    navHealthProviders: '',
    navHcpMembers: '',
    navPatients: '',
    navAlertsManagement: '',
    navCampaignSetup: '',
    navFinancialTransactions: '',
    navHealthProvidersPayment: '',
    navInfluencersPayment: '',
    navAlertManagement: '',
    navCampaignAuthorization: '',
    navOpenTickets: '',
    navAccessViewTransactions: '',
    navUsers: '',
    navInfluencersSetup: '',
    navPromotionManagement: '',
    navCountriesManagement: '',
    navBeneficiariesManagement: '',
    navClarityIntegrations: '',
    navHealthcareModulesLink: '',
    navExchangeRatesLink: '',
    navGroupLink: '',
    navdDiscountCardLink: '',
    navDashboardOperation: '',
    navDashboardFinance: '',
    navDashboardSupport: '',
    navDashboardMarketing: '',
    navRole: '',
    navApiAccess: '',
  };
  translationKeys: string[] = [
    'admin-layout.nav_admin_hub',
    'admin-layout.nav_operations_hub',
    'admin-layout.nav_finance_hub',
    'admin-layout.nav_support_hub',
    'admin-layout.nav_marketing_hub',
    'admin-layout.nav_dashboard',
    'admin-layout.nav_users_teams_roles',
    'admin-layout.nav_general_settings',
    'admin-layout.nav_health_providers',
    'admin-layout.nav_hcp_members',
    'admin-layout.nav_patients',
    'admin-layout.nav_alerts_management',
    'admin-layout.nav_campaign_setup',
    'admin-layout.nav_financial_transactions',
    'admin-layout.nav_health_providers_payment',
    'admin-layout.nav_influencers_payment',
    'admin-layout.nav_alert_management',
    'admin-layout.nav_campaign_authorization',
    'admin-layout.nav_open_tickets',
    'admin-layout.nav_access_view_transactions',
    'admin-layout.nav-healthcare-modules-link',
    'admin-layout.nav-exchange-rates-link',
    'admin-layout.nav-group-link',
    'admin-layout.nav-discount-cards-link',
    'admin-layout.nav_dashboard-operation',
    'admin-layout.nav_dashboard-finance',
    'admin-layout.nav_dashboard-support',
    'admin-layout.nav_dashboard-marketing',
    'admin-layout.nav_clarity_integrations',
    'admin-layout.nav_promotion_management',
    'admin-layout.nav_influencers_setup',
    'admin-layout.nav_roles',
    'admin-layout.nav_countries_management',
    'admin-layout.nav_beneficiaries_management',
    'admin-layout.api_access',
  ];
  $languageChange!: Subscription;
  MENUITEMS: Menu[] = [];

  OPERATION_MENUITEMS: Menu[] = [];
  FINANCE_MENUITEMS: Menu[] = [];
  SUPPORT_MENUITEMS: Menu[] = [];
  MARKETING_MENUITEMS: Menu[] = [];
  ADMIN_MENUITEMS: Menu[] = [];

  items: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);
  public services: ServicesService;
  private languageService: LanguageService;
  private adminRoleService: AdminRoleService;

  constructor() {
    this.services = inject(ServicesService);
    this.adminRoleService = inject(AdminRoleService);
    this.languageService = inject(LanguageService);
    this.getTranslations();
    this.listenForLanguageChangeEvents();
  }

  ngOnDestroy() {
    this.$languageChange && this.$languageChange.unsubscribe();
  }

  public getNavItems(): void {
    this.MENUITEMS = [];
    const adminRole = this.services.sessionService.getUser().adminroles ?? [];

    const usedRole: string[] = [];
    for (let index = 0; index < adminRole!.length; index++) {
      const role = adminRole![index];
      if (role.role_type === AdminRoleType.Super) {
        this.MENUITEMS = this.ADMIN_MENUITEMS;
        this.items.next(this.MENUITEMS);
        return;
      }

      if (!usedRole.some(r => r === role.role_type)) {
        switch (role.role_type) {
          case AdminRoleType.Operation:
            this.MENUITEMS.push(...this.OPERATION_MENUITEMS);
            break;
          case AdminRoleType.Finance:
            this.MENUITEMS.push(...this.FINANCE_MENUITEMS);
            break;
          case AdminRoleType.Support:
            this.MENUITEMS.push(...this.SUPPORT_MENUITEMS);
            break;
          case AdminRoleType.Marketing:
            this.MENUITEMS.push(...this.MARKETING_MENUITEMS);
            break;

          default:
            break;
        }
        usedRole.push(role.role_type);
      }
    }

    this.items.next(this.MENUITEMS);
  }

  listenForLanguageChangeEvents(): void {
    this.$languageChange = this.services.eventService.subscribe(
      'change:language',
      () => {
        this.getTranslations();
      }
    );
  }

  getTranslations(): void {
    this.languageService.get(this.translationKeys).subscribe(translations => {
      this.translations.navAdminHub =
        translations['admin-layout.nav_admin_hub'];
      this.translations.navOperationsHub =
        translations['admin-layout.nav_operations_hub'];
      this.translations.navFinanceHub =
        translations['admin-layout.nav_finance_hub'];
      this.translations.navSupportHub =
        translations['admin-layout.nav_support_hub'];
      this.translations.navMarketingHub =
        translations['admin-layout.nav_marketing_hub'];
      this.translations.navDashboard =
        translations['admin-layout.nav_dashboard'];
      this.translations.navUsersTeamsRoles =
        translations['admin-layout.nav_users_teams_roles'];
      this.translations.navGeneralSettings =
        translations['admin-layout.nav_general_settings'];
      this.translations.navHealthProviders =
        translations['admin-layout.nav_health_providers'];
      this.translations.navHcpMembers =
        translations['admin-layout.nav_hcp_members'];
      this.translations.navPatients = translations['admin-layout.nav_patients'];
      this.translations.navAlertsManagement =
        translations['admin-layout.nav_alerts_management'];
      this.translations.navCampaignSetup =
        translations['admin-layout.nav_campaign_setup'];
      this.translations.navFinancialTransactions =
        translations['admin-layout.nav_financial_transactions'];
      this.translations.navHealthProvidersPayment =
        translations['admin-layout.nav_health_providers_payment'];
      this.translations.navInfluencersPayment =
        translations['admin-layout.nav_influencers_payment'];
      this.translations.navAlertManagement =
        translations['admin-layout.nav_alert_management'];
      this.translations.navCampaignAuthorization =
        translations['admin-layout.nav_campaign_authorization'];
      this.translations.navOpenTickets =
        translations['admin-layout.nav_open_tickets'];
      this.translations.navAccessViewTransactions =
        translations['admin-layout.nav_access_view_transactions'];
      this.translations.navUsers = translations['admin-layout.nav_users'];
      this.translations.navInfluencersSetup =
        translations['admin-layout.nav_influencers_setup'];
      this.translations.navPromotionManagement =
        translations['admin-layout.nav_promotion_management'];
      this.translations.navCountriesManagement =
        translations['admin-layout.nav_countries_management'];
      this.translations.navBeneficiariesManagement =
        translations['admin-layout.nav_beneficiaries_management'];
      this.translations.navClarityIntegrations =
        translations['admin-layout.nav_clarity_integrations'];
      this.translations.navHealthcareModulesLink =
        translations['admin-layout.nav-healthcare-modules-link'];
      this.translations.navExchangeRatesLink =
        translations['admin-layout.nav-exchange-rates-link'];
      this.translations.navGroupLink =
        translations['admin-layout.nav-group-link'];
      this.translations.navDiscountCardLink =
        translations['admin-layout.nav-discount-cards-link'];
      this.translations.navDashboardOperation =
        translations['admin-layout.nav_dashboard-operation'];
      this.translations.navDashboardFinance =
        translations['admin-layout.nav_dashboard-finance'];
      this.translations.navDashboardSupport =
        translations['admin-layout.nav_dashboard-support'];
      this.translations.navDashboardMarketing =
        translations['admin-layout.nav_dashboard-marketing'];
      this.translations.navRole = translations['admin-layout.nav_roles'];
      this.translations.navApiAccess = translations['admin-layout.api_access'];
    });
    this.getRoleMenu();
  }

  getRoleMenu() {
    const hasDiscountView = this.adminRoleService.hasPermission(
      PermissionEnums.DiscountView
    );
    const hasOperation = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardOperation
    );
    const hasHcpView = this.adminRoleService.hasPermission(
      PermissionEnums.HealthcareproviderView
    );
    const hasHcmView = this.adminRoleService.hasPermission(
      PermissionEnums.HealthpackageView
    );
    const hasbuyedView = this.adminRoleService.hasPermission(
      PermissionEnums.BuyedpackageView
    );
    const hasCampaignView = this.adminRoleService.hasPermission(
      PermissionEnums.CampaignView
    );
    const hasAlertView = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardAlert
    );
    const hasFinance = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardFinance
    );
    const hasMarketing = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardMarketing
    );
    const hasSupport = this.adminRoleService.hasPermission(
      PermissionEnums.DashboardSupport
    );
    const hasDashboard = this.adminRoleService.hasPermission(
      PermissionEnums.Dashboard
    );
    const hasPaymentView = this.adminRoleService.hasPermission(
      PermissionEnums.PaymentView
    );
    const hasSettingView = this.adminRoleService.hasPermission(
      PermissionEnums.SettingView
    );
    const hasInvoiceView = this.adminRoleService.hasPermission(
      PermissionEnums.InvoiceView
    );
    const hasCurrencyView = this.adminRoleService.hasPermission(
      PermissionEnums.CurrencieView
    );
    const hasUserView = this.adminRoleService.hasPermission(
      PermissionEnums.UserView
    );
    const hasAdminRoleView = this.adminRoleService.hasPermission(
      PermissionEnums.AdminroleView
    );
    const hasCountryView = this.adminRoleService.hasPermission(
      PermissionEnums.CountryView
    );
    const hasSuperAdminRole = this.adminRoleService.hasPermission(
      PermissionEnums.SuperAdmin
    );

    this.OPERATION_MENUITEMS = [
      { headTitle: this.translations.navOperationsHub },
      {
        title: this.translations.navDashboardOperation,
        icon: 'home-8-line',
        type: 'link',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        active: false,
        rights: hasOperation,
      },
      {
        title: this.translations.navHealthProviders,
        icon: 'hospital-line',
        active: false,
        path: '/admin/healthcare-providers',
        selected: false,
        type: 'link',
        rights: hasHcpView,
      },
      {
        title: this.translations.navHealthcareModulesLink,
        icon: 'apps-2-line',
        active: false,
        path: '/admin/healthcare-package',
        selected: false,
        type: 'link',
        rights: hasHcmView,
      },
      {
        title: this.translations.navPatients,
        icon: 'user-heart-line',
        active: false,
        path: '/admin/buyedpackages',
        selected: false,
        type: 'link',
        rights: hasbuyedView,
      },
      {
        title: this.translations.navCampaignSetup,
        icon: 'megaphone-line',
        active: false,
        path: '/admin/campaigns',
        selected: false,
        type: 'link',
        rights: hasCampaignView,
      },
      {
        title: this.translations.navAlertsManagement,
        icon: 'alarm-warning-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasAlertView,
      },
      {
        title: this.translations.navHcpMembers,
        icon: 'empathize-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasHcpView,
      },
    ];

    this.FINANCE_MENUITEMS = [
      { headTitle: this.translations.navFinanceHub },
      {
        title: this.translations.navDashboardFinance,
        icon: 'home-8-line',
        type: 'link',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        active: false,
        rights: hasFinance,
      },
      {
        title: this.translations.navFinancialTransactions,
        icon: 'wallet-3-line',
        active: false,
        path: '/admin/transactions',
        selected: false,
        type: 'link',
        rights: hasPaymentView,
      },
      {
        title: this.translations.navHealthProvidersPayment,
        icon: 'hospital-line',
        active: false,
        path: '/admin/invoice',
        selected: false,
        type: 'link',
        rights: hasInvoiceView,
      },
      {
        title: this.translations.navPromotionManagement,
        icon: 'percent-line',
        type: 'link',
        path: '/admin/discount',
        selected: false,
        active: false,
        rights: hasDiscountView,
      },
      {
        title: this.translations.navExchangeRatesLink,
        icon: 'exchange-dollar-line',
        active: false,
        path: '/admin/currencies',
        selected: false,
        type: 'link',
        rights: hasCurrencyView,
      },
      {
        title: this.translations.navAlertManagement,
        icon: 'alarm-warning-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasAlertView,
      },
      {
        title: this.translations.navCampaignSetup,
        icon: 'megaphone-line',
        active: false,
        path: '/admin/campaigns',
        selected: false,
        type: 'link',
        rights: hasCampaignView,
      },
    ];

    this.SUPPORT_MENUITEMS = [
      { headTitle: this.translations.navSupportHub },
      {
        title: this.translations.navDashboardSupport,
        icon: 'home-8-line',
        type: 'link',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        active: false,
        rights: hasSupport,
      },
      {
        title: this.translations.navAccessViewTransactions,
        icon: 'wallet-3-line',
        active: false,
        path: '/admin/transactions',
        selected: false,
        type: 'link',
        rights: hasPaymentView,
      },
      {
        title: this.translations.navOpenTickets,
        icon: 'customer-service-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasPaymentView,
      },
    ];

    this.MARKETING_MENUITEMS = [
      { headTitle: this.translations.navMarketingHub },
      {
        title: this.translations.navDashboardMarketing,
        icon: 'home-8-line',
        type: 'link',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        active: false,
        rights: hasMarketing,
      },
      {
        title: this.translations.navPromotionManagement,
        icon: 'percent-line',
        type: 'link',
        path: '/admin/discount',
        selected: false,
        active: false,
        rights: hasDiscountView,
      },
      {
        title: this.translations.navClarityIntegrations,
        icon: 'service-fill',
        type: 'link',
        path: 'javascript:void(0)',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        selected: false,
        active: false,
        rights: hasDiscountView,
      },
    ];

    this.ADMIN_MENUITEMS = [
      { headTitle: this.translations.navAdminHub },
      {
        title: this.translations.navDashboard,
        icon: 'home-8-line',
        type: 'link',
        path: '/admin/monitoring',
        selected: false,
        active: false,
        rights: hasDashboard,
      },
      {
        title: this.translations.navUsersTeamsRoles,
        icon: 'user-line',
        active: false,
        path: '/admin',
        selected: false,
        type: 'link',
        rights: hasUserView,
      },
      {
        title: this.translations.navRole,
        icon: 'shield-user-line',
        active: false,
        path: '/admin/role',
        selected: false,
        type: 'link',
        rights: hasAdminRoleView,
      },
      {
        title: this.translations.navGeneralSettings,
        icon: 'settings-3-line',
        active: false,
        path: '/admin/settings',
        selected: false,
        type: 'link',
        rights: hasSettingView,
      },
      {
        title: this.translations.navCountriesManagement,
        icon: 'flag-line',
        active: false,
        path: '/admin/country',
        selected: false,
        type: 'link',
        rights: hasCountryView,
      },
      {
        title: this.translations.navBeneficiariesManagement,
        icon: 'group-line',
        active: false,
        path: '/admin/beneficiaries',
        selected: false,
        type: 'link',
        rights: hasSuperAdminRole,
      },
      {
        title: this.translations.navCampaignSetup,
        icon: 'megaphone-line',
        active: false,
        path: '/admin/campaigns',
        selected: false,
        type: 'link',
        rights: hasCampaignView,
      },
      {
        title: this.translations.navHealthProviders,
        icon: 'hospital-line',
        active: false,
        path: '/admin/healthcare-providers',
        selected: false,
        type: 'link',
        rights: hasHcpView,
      },
      {
        title: this.translations.navHealthcareModulesLink,
        icon: 'apps-2-line',
        active: false,
        path: '/admin/healthcare-package',
        selected: false,
        type: 'link',
        rights: hasHcmView,
      },
      {
        title: this.translations.navPatients,
        icon: 'user-heart-line',
        active: false,
        path: '/admin/buyedpackages',
        selected: false,
        type: 'link',
        rights: hasbuyedView,
      },
      {
        title: this.translations.navExchangeRatesLink,
        icon: 'exchange-dollar-line',
        active: false,
        path: '/admin/currencies',
        selected: false,
        type: 'link',
        rights: hasCurrencyView,
      },
      {
        title: this.translations.navFinancialTransactions,
        icon: 'wallet-3-line',
        active: false,
        path: '/admin/transactions',
        selected: false,
        type: 'link',
        rights: hasPaymentView,
      },
      {
        title: this.translations.navHealthProvidersPayment,
        icon: 'hospital-line',
        active: false,
        path: '/admin/invoice',
        selected: false,
        type: 'link',
        rights: hasInvoiceView,
      },
      {
        title: this.translations.navPromotionManagement,
        icon: 'percent-line',
        type: 'link',
        path: '/admin/discount',
        selected: false,
        active: false,
        rights: hasDiscountView,
      },
      {
        title: this.translations.navApiAccess,
        icon: 'command-line',
        type: 'link',
        path: '/admin/api-access',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        selected: false,
        active: false,
        rights: hasSuperAdminRole,
      },
      {
        title: this.translations.navAlertsManagement,
        icon: 'alarm-warning-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasAlertView,
      },
      {
        title: this.translations.navHcpMembers,
        icon: 'empathize-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasHcpView,
      },
      {
        title: this.translations.navOpenTickets,
        icon: 'customer-service-line',
        active: false,
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        path: 'javascript:void(0)',
        selected: false,
        type: 'link',
        rights: hasPaymentView,
      },
      {
        title: this.translations.navClarityIntegrations,
        icon: 'service-fill',
        type: 'link',
        path: 'javascript:void(0)',
        badgeClass:
          'inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-kimbo-blue bg-kimbo-blue bg-opacity-10 rounded-full',
        badgeValue: 'coming-soon',
        selected: false,
        active: false,
        rights: hasDiscountView,
      },
    ];

    this.getNavItems();
  }
}
